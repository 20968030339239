import React, { useState } from 'react';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Link,
  Stack,
  Button,
  useDisclosure,
  Text,
} from '@efishery/onefish';
import { FaBug } from 'react-icons/fa';

const initialPositionState = {
  right: -36,
  bottom: 4,
};

const ReportBug = ({ pic }) => {
  const [position, setPosition] = useState(initialPositionState);
  const teamName = pic.team ? `team ${pic.team}` : 'kami';
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleMouseOver = () => {
    setPosition((prevState) => ({ ...prevState, right: -8 }));
  };

  const handleMouseOut = () => {
    if (isOpen) return;

    onClose();
    setPosition(initialPositionState);
  };

  return (
    <Box
      w="48"
      cursor="pointer"
      position="fixed"
      transition="ease-in"
      transitionDuration="0.125s"
      bottom={position.bottom}
      right={position.right}
      bgColor="green.500"
      fontSize="smaller"
      zIndex="10"
      rounded="full"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Stack direction="row" spacing={4}>
            <Button
              leftIcon={<FaBug color="white" />}
              gap="2"
              colorScheme="green.500"
              variant="ghost"
              color="white"
              rounded="full"
            >
              Nemu Bugs?
            </Button>
          </Stack>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Nemuin Bugs?</PopoverHeader>
          <PopoverBody>
            Kasih tau {teamName} di{' '}
            <Link href={pic.reportUrl} isExternal>
              <Text as="span" color="brand.500">
                {pic.channelName || 'sini'}
              </Text>
            </Link>
            , terima kasih
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ReportBug;
