import React, { Suspense } from "react";
import { OneFishProvider, Flex, Spinner } from "@efishery/onefish";
import { ThemeProvider } from "@efishery/onefish-antd";
import "@fontsource/poppins";
import "./App.scss";
/*
  React Date Picker Global Set
  You can override this CSS per-component too!
*/
import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";

import routes from "./routes";
import { FlagrProvider, MessageProvider, NotificationProvider } from "contexts";
import { useApmRum } from "libs/hooks";

const Fallback = () => {
  return (
    <Flex h="100vh" w="100vw" justifyContent="center" alignItems="center">
      <Spinner size="xl" color="brand.500" />
    </Flex>
  );
};

export default () => {
  useApmRum({
    serviceName: "efishery-dashboard-internal",
    serverUrl: process.env.REACT_APP_APM_HOST,
    environment: process.env.REACT_APP_ENV,
  });

  return (
    <NotificationProvider>
      <MessageProvider>
        <OneFishProvider>
          <ThemeProvider>
            <FlagrProvider>
              <Suspense fallback={<Fallback />}>
                <Router>{renderRoutes(routes)}</Router>
              </Suspense>
            </FlagrProvider>
          </ThemeProvider>
        </OneFishProvider>
      </MessageProvider>
    </NotificationProvider>
  );
};
