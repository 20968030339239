import React from 'react'

const DashboardPage = React.lazy(() => import('packages/user/leads/mergeLeads/src/pages/dashboard.page'))

export default {
  path: '/leads/merge-lead',
  context: 'Leads',
  title: 'Merge Leads',
  exact: true,
  component: DashboardPage,
  showMenu: true,
  searchable: true
}
