import React from 'react';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link, matchPath } from 'react-router-dom';
import classNames from 'classnames';

export default ({ routes, location }) => {
  const isExact = (pathData) => {
    if(pathData === null) {
      return false
    }
    else {
      return pathData.isExact
    }
  }

  return (
    <Nav tabs>
      {
        routes.map(({ path, title, showMenu }) => (
          <NavItem key={path}>
            <NavLink
              tag={Link}
              to={path.includes('edit') ? '#' : path}
              active={isExact(matchPath(location.pathname,path))}
              className={classNames({'d-none': showMenu===false && !isExact(matchPath(location.pathname,path))})}
            >
              {title}
            </NavLink>
          </NavItem>
        ))
      }
    </Nav>
  )
}