import create from 'zustand';
import { persist } from 'zustand/middleware';
import Jsflagr from 'jsflagr';

const { REACT_APP_FLAGR_AUTH, REACT_APP_FLAGR_BASE_URL } = process.env;

const postEvaluationAsync = (api, body) => {
  return new Promise((resolve, reject) => {
    api.postEvaluation(body, (error, data) => {
      if (error) reject(error);
      resolve(data);
    });
  });
};

const defaultState = {
  flagrData: {},
  error: null,
};

const actions = (set, get) => ({
  fetchFlagrData: async (flagKey, entityContext) => {
    try {
      const flagrData = get().flagrData;

      if (!flagrData[flagKey]) {
        const apiClient = new Jsflagr.ApiClient();
        apiClient.basePath = REACT_APP_FLAGR_BASE_URL;
        apiClient.defaultHeaders = {
          Authorization: REACT_APP_FLAGR_AUTH,
        };

        const body = new Jsflagr.EvalContext();
        body.flagKey = flagKey;
        body.entityContext = entityContext;

        const api = new Jsflagr.EvaluationApi(apiClient);
        const data = await postEvaluationAsync(api, body);
        const flagrState = {
          ...flagrData,
          [flagKey]: data,
        };

        set((state) => ({
          ...state,
          flagrData: flagrState,
        }));
      }
    } catch (error) {
      set((state) => ({
        ...state,
        error,
      }));
    }
  },
  setFlagrData: (value) =>
    set((state) => ({
      ...state,
      flagrData: value,
      error: null,
    })),
  resetFlagrData: () =>
    set(() => ({
      ...defaultState,
    })),
});

export const useFlagrStore = create(
  persist((set, get) => ({
    ...defaultState,
    ...actions(set, get),
  }))
);
