import React from "react";
import ContentTabs from "components/ContentTabs";
import Webview from "libs/utils/webview";
import { CORE } from "libs/constants/pic";
import withFlagr from "components/withFlagr";
import { flagKey, entityContext } from "libs/constants/point";

const PointWebview = new Webview({
  baseUrl:
    process.env.REACT_APP_POINT_MODULAR_FE ||
    "https://stg-area-mod-fe-platform-point.efishery.com",
  scope: "point",
});

const ViewPointPage = React.lazy(() =>
  import("packages/sales/points/legacy/viewPoints")
);
const ViewPointV1Page = PointWebview.renderWebview({ src: "" });
const CreatePointPage = React.lazy(() =>
  import("packages/sales/points/legacy/formPoints")
);
const CreatePointV1Page = PointWebview.renderWebview({ src: "/create" });
const EditPointPage = React.lazy(() =>
  import("packages/sales/points/legacy/formPoints")
);
const EditPointV1Page = PointWebview.renderWebview({ src: "/edit" });

export default {
  path: "/points",
  title: "Points",
  name: "points",
  group: "Sales",
  component: ContentTabs,
  pic: {
    ...CORE,
  },
  routes: [
    {
      path: "/points",
      context: "Points",
      title: "View Points",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        ViewPointV1Page,
        ViewPointPage
      ),
      searchable: true,
    },
    {
      path: "/points/create",
      context: "Points",
      title: "Create",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        CreatePointV1Page,
        CreatePointPage
      ),
      searchable: true,
    },
    {
      path: "/points/view/:id/(edit)?",
      context: "Points",
      title: "Detail",
      showMenu: false,
      component: withFlagr(
        flagKey,
        entityContext,
        EditPointV1Page,
        EditPointPage
      ),
    },
  ],
};
