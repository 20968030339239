import React, { createContext, useContext } from "react";
import { notification } from "@efishery/onefish-antd";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationAPI, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={notificationAPI}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
