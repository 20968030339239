import React from "react";
import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";

import NavTabs from "./NavTabs";
import ReportBug from "./ReportBug.component";

import { Heading } from "@efishery/onefish";

const ContentTabs = ({ route, location }) => {
  return (
    <React.Fragment>
      <Heading mb="5">{route.title}</Heading>
      <NavTabs routes={route.routes} location={location} />
      <TabContent>
        <TabPane className="pt-3 pb-3">{renderRoutes(route.routes)}</TabPane>
      </TabContent>
      {route?.pic && <ReportBug pic={route.pic} />}
    </React.Fragment>
  );
};

export default withRouter(ContentTabs);
