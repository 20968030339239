import React from "react";
import { useLocation } from "react-router-dom";
import { matchRoutes, renderRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";

import { Heading } from "@efishery/onefish";

const ContentWithoutTabs = ({ route }) => {
  const location = useLocation();
  const [firstMatchRoute] = matchRoutes(route.routes, location.pathname);
  const title = firstMatchRoute?.route?.title;

  return (
    <>
      {title && <Heading mb="5">{title}</Heading>}
      {renderRoutes(route.routes)}
    </>
  );
};

export default withRouter(ContentWithoutTabs);
