import React from "react";
import ContentTabs from "components/ContentTabs";
import Webview from "libs/utils/webview";

import { CORE } from "libs/constants/pic";
import withFlagr from "components/withFlagr";
import { flagKey, entityContext } from "libs/constants/region";

const RegionWebview = new Webview({
  baseUrl:
    process.env.REACT_APP_REGION_MODULAR_FE ||
    "https://stg-area-mod-fe-platform-region.efishery.com",
  scope: "region",
});

const ViewRegionPage = React.lazy(() =>
  import("packages/sales/region/legacy/view")
);
const ViewRegionV1Page = RegionWebview.renderWebview({ src: "" });
const CreateRegionPage = React.lazy(() =>
  import("packages/sales/region/legacy/formRegion")
);
const CreateRegionV1Page = RegionWebview.renderWebview({ src: "/create" });
const EditRegionPage = React.lazy(() =>
  import("packages/sales/region/legacy/formRegion")
);
const EditRegionV1Page = RegionWebview.renderWebview({ src: "/edit" });

export default {
  path: "/region",
  title: "Region",
  name: "region",
  group: "Sales",
  component: ContentTabs,
  pic: {
    ...CORE,
  },
  routes: [
    {
      path: "/region",
      context: "Region",
      title: "View Region",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        ViewRegionV1Page,
        ViewRegionPage
      ),
      searchable: true,
    },
    {
      path: "/region/create",
      context: "Region",
      title: "Create",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        CreateRegionV1Page,
        CreateRegionPage
      ),
      searchable: true,
    },
    {
      path: "/region/view/:id/(edit)?",
      context: "Region",
      title: "Detail",
      showMenu: false,
      component: withFlagr(
        flagKey,
        entityContext,
        EditRegionV1Page,
        EditRegionPage
      ),
    },
  ],
};
