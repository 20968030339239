import {
  useRef,
  useEffect,
  useState,
} from 'react';
import { init } from '@elastic/apm-rum'

import currencyFormat from '../utils/currencyFormat';

const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(
    didMountRef.current ?
      fn :
      () => { didMountRef.current = true }
    , [...inputs]
  );
}

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debounce = () => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    }
  }

  useEffect(debounce, [value]);

  return debouncedValue;
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useSearchMemo = (funcLocal, funcRemote, query) => {
  const prevQuery = usePrevious(query);

  const search = (query.includes(prevQuery) && prevQuery !== '') ? funcLocal : funcRemote

  return search;
}

const useForm = (setter, callback) => {
  // const [values, setValues] = useState(initialState);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    callback();
  };

  const handleChange = (event) => {
    event.persist();
    setter(values => ({ ...values, [event.target.name]: event.target.value }));
  };

  const handleOptionChange = (event, fieldName) => {
    event.persist();
    setter(values => ({ ...values, [fieldName]: event.target.value }));
  };

  const handleMultipleOptionChange = (event, fieldName) => {
    event.persist();
    setter(values => ({ 
      ...values, 
      [fieldName]: {...values[fieldName], [event.target.value]: event.target.checked}
    }));
  };

  const handleDatePickerChange = (fieldName, value) => {
    setter(values => ({ ...values, [fieldName]: value }));
  }

  const handleCurrencyChange = (event, realName, maskedName) => {
    event.persist();
    setter(values => ({
      ...values,
      [maskedName]: currencyFormat(event.target.value),
      [realName]: event.target.value.replace(/[^0-9.]/g, ''),
    }));
  }

  return {
    handleChange,
    handleOptionChange,
    handleMultipleOptionChange,
    handleDatePickerChange,
    handleCurrencyChange,
    handleSubmit,
  }
};

const useApmRum = ({ serviceName, serverUrl, environment }) => {
  useEffect(() => {
    init({
      serviceName,
      serverUrl,
      environment
    })

    // eslint-disable-next-line 
  }, [])
}

export {
  useDidUpdateEffect,
  useDebounce,
  usePrevious,
  useSearchMemo,
  useForm,
  useApmRum
}
