export default {
  getUser: () => {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : {};
  },
  setUser: (userData) => localStorage.setItem("user", JSON.stringify(userData)),
  setRolePermission: (rolePermission) =>
    localStorage.setItem("permission", JSON.stringify(rolePermission)),
  setLatestVisitMenu: (menu) =>
    localStorage.setItem("dashin-menu", JSON.stringify(menu)),
  getLatestVisitMenu: () => JSON.parse(localStorage.getItem("dashin-menu")),
  isUserLogin: () => (localStorage.getItem("user") ? true : false),
  logout: () => localStorage.removeItem("user"),
  getToken: () => (JSON.parse(localStorage.getItem("user")) || {}).token,
  getRefreshToken: () =>
    (JSON.parse(localStorage.getItem("user")) || {}).refresh_token,
  updateToken(token, refresh_token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.token = token;
    if (refresh_token) user.refresh_token = refresh_token;
    localStorage.setItem("user", JSON.stringify(user));
  },
};
