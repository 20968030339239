import React/*, {useEffect}*/ from 'react';
import userStore from "../stores/userStore";
import { Redirect } from 'react-router-dom';

const withAuth = Component => (props) => {
  const isLogin = userStore.isUserLogin();

  return isLogin ? <Component {...props} /> : <Redirect to="/login" />;
}

export default withAuth;