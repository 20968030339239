import React, { useEffect } from 'react';
import shallow from 'zustand/shallow';

import ExperimentalFeatureLabel from 'components/ExperimentalFeatureLabel.component';

import { useFlagrStore } from 'stores/useFlagrStore';
import userStore from 'stores/userStore';
import { useHideTopbar } from 'libs/hooks/useHideTopbar.hook';

const withFlagr =
  (flagKey, entityContext, Component, AnotherComponent) => (props) => {
    const [flagrData, fetchFlagrData] = useFlagrStore(
      (state) => [state.flagrData, state.fetchFlagrData],
      shallow
    );

    useEffect(() => {
      const user = userStore.getUser();
      if (user?.email) {
        fetchFlagrData(flagKey, entityContext);
      }
    }, []);

    const hideTopbar = useHideTopbar(props.location);

    return flagrData[flagKey]?.variantKey === 'on' ? (
      <ExperimentalFeatureLabel hideTopbar={hideTopbar}>
        <Component {...props} />
      </ExperimentalFeatureLabel>
    ) : (
      <AnotherComponent {...props} />
    );
  };

export default withFlagr;
