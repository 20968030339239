import React from 'react';
import { Box, Text } from '@efishery/onefish';

const ExperimentalFeatureLabel = ({ children, hideTopbar }) => {
  return (
    <>
      <Box
        position="fixed"
        top={hideTopbar ? '6' : '20'}
        right="0"
        bgColor="red.500"
        textAlign="center"
        fontWeight="semibold"
        fontSize="smaller"
        p="2"
        px="20"
        color="white"
        transform="rotate(45deg) translate(60px, -40px)"
        transition="transform 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
        zIndex="10"
      >
        <Text>Experimental</Text>
        <Text>Feature</Text>
      </Box>

      {children}
    </>
  );
};

export default ExperimentalFeatureLabel;
