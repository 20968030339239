import React, { useEffect } from "react";

class Webview {
  baseUrl = "";
  scope = "";

  constructor({ baseUrl = "", scope = "" }) {
    this.baseUrl = baseUrl;
    this.scope = scope;
  }

  renderWebview({ src = "" } = {}) {
    return ({ history, location }) => {
      const query = location.pathname.replace(window.location.pathname, "");
      useEffect(() => {
        window.addEventListener("message", (message) => {
          const { data } = message;
          if (data.scope === this.scope && data.type === "redirect") {
            history.push({
              pathname: data.to,
            });
          }
        });

        return () => {
          window.removeEventListener("message");
        };
      }, []);

      return React.createElement("iframe", {
        src: this.baseUrl + src + query,
        loading: "lazy",
        border: "none",
        width: "100%",
        sandbox:
          "allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-forms",
        style: {
          minHeight: "calc(2 * 100vh)",
          maxHeight: "auto",
        },
      });
    };
  }
}

export default Webview;
