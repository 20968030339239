import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { invert } from "lodash";
import { apiFlagr } from "libs/api";
import userStore from "stores/userStore";

/**
 * When we create a new flag, the id for staging, uat, and production are ALWAYS sequential.
 */
const ID_INCREMENT_BY = { staging: 0, uat: 1, production: 2 }[
  process.env.REACT_APP_ENV
];

/**
 * Set with flag id for staging env
 */
const FLAG_ID_BY_LABEL = {
  is_under_maintenance: 3049,
  page_under_maintenance: 3052,
};

for (const key in FLAG_ID_BY_LABEL) {
  FLAG_ID_BY_LABEL[key] = FLAG_ID_BY_LABEL[key] + ID_INCREMENT_BY;
}

const FLAG_LABEL_BY_ID = invert(FLAG_ID_BY_LABEL);

/**
 * The initial value of the flag, that used in the code
 */
const INITIAL_VALUE = {
  is_under_maintenance: false,
  page_under_maintenance: [],
};

const remapFlagrResponseToState = (evaluationResults = []) => {
  return evaluationResults.reduce(
    (acc, { flagID, variantKey, variantAttachment }) => {
      const flagLabel = FLAG_LABEL_BY_ID[flagID];

      if (typeof INITIAL_VALUE[flagLabel] === "boolean") {
        acc[flagLabel] = variantKey === "on";
        return acc;
      }

      if (variantAttachment) {
        acc[flagLabel] = variantAttachment.value;
        return acc;
      }

      return acc;
    },
    {}
  );
};

const removeFromActiveFlags = (keysToBeRemoved = []) => {
  for (let i = 0; i < keysToBeRemoved.length; i++) {
    const index = activeFlags.indexOf(keysToBeRemoved[i]);
    if (index !== -1) activeFlags.splice(index, 1);
  }
};

let activeFlags = Object.keys(FLAG_ID_BY_LABEL);

const FlagrContext = createContext(INITIAL_VALUE);

export const FlagrProvider = ({ children }) => {
  const [value, setValue] = useState(INITIAL_VALUE);

  const entityID = userStore.getUser().email;
  const prevEntityID = useRef(entityID);

  if (entityID !== prevEntityID.current) {
    activeFlags = Object.keys(FLAG_ID_BY_LABEL); // reset active flags to all flags
    prevEntityID.current = entityID;
  }

  useLayoutEffect(() => {
    const fetchFlagrBatch = () => {
      const flagIDs = [...new Set(activeFlags)].map(
        (key) => FLAG_ID_BY_LABEL[key]
      );
      if (!flagIDs) return;

      apiFlagr
        .getFlagrEvaluationBatch({
          flagIDs,
          entityID,
          entityContext: { email: entityID },
        })
        .then(({ evaluationResults }) => {
          if (entityID != prevEntityID.current) return;
          setValue((prev) => ({
            ...prev,
            ...remapFlagrResponseToState(evaluationResults),
          }));
        })
        .catch(() => {});
    };

    fetchFlagrBatch();
    activeFlags = [];

    const t = setInterval(() => fetchFlagrBatch(), 60_000);
    return () => clearInterval(t);
  }, [entityID]);

  return (
    <FlagrContext.Provider value={value}>{children}</FlagrContext.Provider>
  );
};

export const useFlagrV2 = (flagLabels = []) => {
  useLayoutEffect(() => {
    activeFlags.push(...flagLabels);
    return () => removeFromActiveFlags(flagLabels);
  }, []);

  return useContext(FlagrContext);
};
