import React from "react";
import { Redirect } from "react-router-dom";
import ContentTabs from "components/ContentTabs";
import withAuth from "components/withAuth";
import NoMatch from "components/NoMatch";
import userStore from "stores/userStore";
import MergeLeads from "packages/user/leads/mergeLeads";
import ContentWithoutTabs from "components/ContentWithoutTabs";

import sales from "packages/sales";

import { isAuth2Enable } from "libs/api";

const Dashboard = React.lazy(() => import("packages/dashboard"));
const AlertIAM = React.lazy(() => import("packages/efisheryIam/alert"));
const ViewLeads = React.lazy(() => import("packages/user/leads/ViewLeads"));
const FormLeads = React.lazy(() => import("packages/user/leads/FormLeads"));
const Login = React.lazy(() => import("packages/platform/login"));
const FormEvent = React.lazy(() =>
  import("packages/marketing/events/FormEvent")
);
const ViewEvent = React.lazy(() =>
  import("packages/marketing/events/ViewEvent")
);
const Broadcast = React.lazy(() => import("packages/marketing/broadcast"));
const ViewCustomers = React.lazy(() =>
  import("packages/user/customers/ViewCustomers")
);
const EditCustomer = React.lazy(() =>
  import("packages/user/customers/FormCustomer")
);
const FormSubmission = React.lazy(() =>
  import("packages/finance/submissions/FormSubmission")
);
const ViewFeeds = React.lazy(() => import("packages/finance/feeds/ViewFeeds"));
const FormFeeds = React.lazy(() => import("packages/finance/feeds/FormFeeds"));
const ViewPricings = React.lazy(() =>
  import("packages/finance/pricings/ViewPricings")
);
const FormPricings = React.lazy(() =>
  import("packages/finance/pricings/FormPricings")
);
const OrderList = React.lazy(() =>
  import("packages/smartFarm/orders/pages/OrderList")
);
const OrderDetail = React.lazy(() =>
  import("packages/smartFarm/orders/pages/OrderDetail")
);
const ViewPromo = React.lazy(() =>
  import("packages/smartFarm/promo/ViewPromo")
);
const FormPromo = React.lazy(() =>
  import("packages/smartFarm/promo/FormPromo")
);
const ViewBilling = React.lazy(() =>
  import("packages/smartFarm/billings/ViewBilling")
);
const FormBilling = React.lazy(() =>
  import("packages/smartFarm/billings/FormBilling")
);
const TicketReturnList = React.lazy(() =>
  import("packages/smartFarm/tickets/ticketReturn/pages/TicketReturnList")
);
const CreateTicketReturn = React.lazy(() =>
  import("packages/smartFarm/tickets/ticketReturn/pages/CreateTicketReturn")
);
const TicketAssemblyList = React.lazy(() =>
  import("packages/smartFarm/tickets/ticketAssembly/pages/TicketAssemblyList")
);
const TicketAssemblyListV2 = React.lazy(() =>
  import("packages/smartFarm/tickets/ticketAssembly/pages/TicketAssemblyListV2")
);
const TicketAssemblyDetailV2 = React.lazy(() =>
  import(
    "packages/smartFarm/tickets/ticketAssembly/pages/TicketAssemblyDetailV2"
  )
);
const TicketInstallationList = React.lazy(() =>
  import(
    "packages/smartFarm/tickets/ticketInstallation/pages/TicketInstallationList"
  )
);
const TicketInstallationListV2 = React.lazy(() =>
  import(
    "packages/smartFarm/tickets/ticketInstallation/pages/TicketInstallationListV2"
  )
);
const TicketInstallationDetailV2 = React.lazy(() =>
  import(
    "packages/smartFarm/tickets/ticketInstallation/pages/TicketInstallationDetailV2"
  )
);
const TicketDetail = React.lazy(() =>
  import("packages/smartFarm/tickets/TicketDetailPage")
);
const TicketCustomerList = React.lazy(() =>
  import("packages/smartFarm/tickets/ticketCustomer/pages/TicketCustomerList")
);
const AddUser = React.lazy(() =>
  import("packages/user/customers/users/AddUsers")
);
const ProductForm = React.lazy(() =>
  import("packages/user/customers/FormProducts")
);
const AppUserForm = React.lazy(() =>
  import("packages/user/customers/FormAppUsers")
);
const DashboardReminders = React.lazy(() =>
  import("packages/sales/reminders/DashboardReminders")
);
const FormReminders = React.lazy(() =>
  import("packages/sales/reminders/FormReminders")
);
const FormAddTrial = React.lazy(() =>
  import("packages/user/leads/FormAddCustomer")
);

const DashboardInventory = React.lazy(() =>
  import("packages/inventory/dashboard")
);
const Locations = React.lazy(() =>
  import("packages/inventory/dashboard/Locations")
);
const LocationDetail = React.lazy(() =>
  import("packages/inventory/dashboard/LocationDetail")
);
const Fields = React.lazy(() => import("packages/inventory/dashboard/Fields"));
const FieldDetail = React.lazy(() =>
  import("packages/inventory/dashboard/FieldDetail")
);
const CreateTransfer = React.lazy(() =>
  import("packages/inventory/transfers/CreateTransfer")
);
const Transfers = React.lazy(() => import("packages/inventory/transfers"));
const ProductList = React.lazy(() =>
  import("packages/products/pages/ProductList")
);
const ProductDetail = React.lazy(() =>
  import("packages/products/pages/ProductDetail")
);
const VisitList = React.lazy(() => import("packages/visit/pages/VisitList"));
const VisitDetail = React.lazy(() =>
  import("packages/visit/pages/VisitDetail")
);
const BatchItemList = React.lazy(() =>
  import("packages/productionBatch/pages/ItemList")
);
const BatchItemDetail = React.lazy(() =>
  import("packages/productionBatch/pages/ItemDetail")
);

export const default_routes = [
  {
    path: "/iam",
    context: "IAM",
    title: "eFishery IAM",
    name: "iam",
    component: AlertIAM,
    searchable: true,
  },
  {
    path: "/event",
    title: "Events",
    name: "event",
    group: "Marketing",
    component: ContentTabs,
    routes: [
      {
        path: "/event",
        context: "Marketing",
        title: "View Events",
        exact: true,
        component: ViewEvent,
        searchable: true,
      },
      {
        path: "/event/create",
        context: "Marketing",
        title: "Create Events",
        component: FormEvent,
        searchable: true,
      },
      {
        path: "/event/:id/(edit)?",
        title: "Edit Event",
        component: FormEvent,
        showMenu: false,
      },
    ],
  },
  {
    path: "/leads",
    title: "Leads",
    name: "leads",
    group: "User",
    component: ContentTabs,
    routes: [
      {
        path: "/leads",
        context: "Leads",
        title: "View Leads",
        exact: true,
        component: ViewLeads,
        searchable: true,
      },
      {
        path: "/leads/users/:id/create",
        title: "Create User Trial",
        context: "Leads",
        component: FormAddTrial,
        showMenu: false,
      },
      {
        path: "/leads/view/:id/view",
        title: "Edit Lead",
        context: "Leads",
        exact: true,
        component: FormLeads,
        showMenu: false,
      },
      {
        path: "/leads/view/:id/upgrade",
        title: "Upgrade Customer",
        context: "Leads",
        exact: true,
        component: ProductForm,
        showMenu: false,
      },
      {
        path: "/leads/edit/:id/(edit)?",
        title: "Edit Lead",
        context: "Leads",
        exact: true,
        component: FormLeads,
        showMenu: false,
      },
      MergeLeads,
    ],
  },
  {
    path: "/customers",
    title: "Customers",
    name: "customers",
    group: "User",
    component: ContentTabs,
    routes: [
      {
        path: "/customers",
        context: "Customers",
        title: "View Customers",
        exact: true,
        component: ViewCustomers,
        searchable: true,
      },
      {
        path: "/customers/users/:id/create",
        title: "Create User",
        component: AddUser,
        showMenu: false,
      },
      {
        path: "/customers/product/:id/(edit)?",
        title: "Add Product",
        showMenu: false,
        component: ProductForm,
      },
      {
        path: "/customers/appusers/:leadId/create",
        title: "Create App User",
        component: AppUserForm,
        showMenu: false,
      },
      {
        path: "/customers/appusers/:id/(edit)?",
        title: "Detail App Users",
        showMenu: false,
        component: AppUserForm,
      },
      {
        path: "/customers/view/:id/(edit)?",
        title: "Detail Customer",
        component: EditCustomer,
        showMenu: false,
      },
      {
        path: "/customers/edit/:id/(edit)?",
        title: "Edit Customer",
        component: FormLeads,
        showMenu: false,
      },
    ],
  },
  {
    path: "/send-broadcast",
    context: "Broadcast",
    title: "Send Broadcast",
    name: "broadcast",
    component: Broadcast,
    group: "Marketing",
    searchable: true,
  },
  {
    path: "/dashboard",
    context: "Dashboard",
    title: "Stock Dashboard",
    name: "inventory-feeder",
    group: "Inventory MGMT",
    searchable: true,
    component: DashboardInventory,
    routes: [
      {
        path: "/dashboard/by-area",
        component: Locations,
        name: "inventory-feeder",
        exact: true,
        showMenu: false,
      },
      {
        path: "/dashboard/by-area/:id",
        component: LocationDetail,
        name: "inventory-feeder",
        exact: true,
        showMenu: false,
      },
      {
        path: "/dashboard/by-field",
        component: Fields,
        name: "inventory-feeder",
        exact: true,
        showMenu: false,
      },
      {
        path: "/dashboard/by-field/:uuid",
        component: FieldDetail,
        name: "inventory-feeder",
        exact: true,
        showMenu: false,
      },
    ],
  },
  {
    path: "/transfers",
    context: "Transfers",
    title: "Stock Transfers",
    name: "inventory-feeder",
    component: Transfers,
    group: "Inventory MGMT",
    searchable: true,
    routes: [
      {
        path: "/transfers/create-transfer",
        component: CreateTransfer,
        name: "inventory-feeder",
        showMenu: false,
      },
    ],
  },
  {
    path: "/products",
    title: "Products",
    name: "product-management",
    group: "Product MGMT",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/products",
        title: "Products",
        name: "product-management",
        context: "Products",
        component: ProductList,
        exact: true,
        searchable: true,
      },
      {
        path: "/products/create",
        title: "Create",
        name: "product-management",
        context: "Products",
        component: ProductDetail,
        exact: true,
        showMenu: false,
      },
      {
        path: "/products/:uuid",
        title: "Preview",
        name: "product-management",
        context: "Products",
        component: ProductDetail,
        exact: true,
        showMenu: false,
      },
      {
        path: "/products/:uuid/(edit)?",
        title: "Edit",
        name: "product-management",
        context: "Products",
        component: ProductDetail,
        exact: true,
        showMenu: false,
      },
    ],
  },
  {
    path: "/batch",
    title: "Batch",
    name: "product-management",
    group: "Product MGMT",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/batch",
        title: "Batch",
        context: "Batch",
        component: BatchItemList,
        exact: true,
        searchable: true,
      },
      {
        path: "/batch/:uuid",
        title: "Item Detail",
        context: "Batch",
        component: BatchItemDetail,
        exact: true,
        searchable: true,
      },
    ],
  },
  {
    path: "/submissions",
    context: "Submissions",
    title: "Submissions",
    name: "submissions",
    group: "Finance",
    component: ContentTabs,
    routes: [
      {
        path: "/submissions",
        context: "Submissions",
        title: "Create Submission",
        component: FormSubmission,
        exact: true,
        searchable: true,
      },
    ],
  },
  {
    path: "/feeds",
    title: "Feeds",
    name: "feeds",
    group: "Finance",
    component: ContentTabs,
    routes: [
      {
        path: "/feeds",
        context: "Feeds",
        title: "View Feeds",
        exact: true,
        component: ViewFeeds,
        searchable: true,
      },
      {
        path: "/feeds/create",
        context: "Feeds",
        title: "Create Feed",
        component: FormFeeds,
        searchable: true,
      },
      {
        path: "/feeds/:id/(edit)?",
        title: "Edit Feed",
        component: FormFeeds,
        showMenu: false,
      },
    ],
  },
  {
    path: "/pricings",
    title: "Pricings",
    name: "pricings",
    group: "Finance",
    component: ContentTabs,
    routes: [
      {
        path: "/pricings",
        context: "Pricings",
        title: "View Pricings",
        exact: true,
        component: ViewPricings,
        searchable: true,
      },
      {
        path: "/pricings/create",
        context: "Pricings",
        title: "Create Pricing",
        component: FormPricings,
        searchable: true,
      },
      {
        path: "/pricings/:id/(edit)?",
        title: "Edit Pricing",
        component: FormPricings,
        showMenu: false,
      },
    ],
  },
  // 2022-07-30
  // @sjarif.hd
  // Gak dipake ini mah
  // {
  //   path: '/aqualisan',
  //   title: 'Aqualisan',
  //   name: 'aqualisan',
  //   group: 'Feeder',
  //   component: ContentTabs,
  //   routes: [
  //     {
  //       path: '/aqualisan',
  //       context: 'Aqualisan',
  //       title: 'List Order Aqualisan',
  //       exact: true,
  //       component: ViewAqualisan,
  //       searchable: true
  //     },
  //     {
  //       path: '/aqualisan/:orderID/:batchID',
  //       title: 'Detail Order Aqualisan',
  //       exact: true,
  //       component: DetailAqualisan,
  //       showMenu: false
  //     }
  //   ]
  // },
  // 2022-07-30
  // @sjarif.hd
  // Gak dipake ini mah
  // {
  //   path: '/inventories',
  //   title: 'Inventories',
  //   name: 'inventories',
  //   group: 'Feeder',
  //   component: ContentTabs,
  //   routes: [
  //     {
  //       path: '/inventories',
  //       context: 'Inventories',
  //       title: 'Detail Warehouse',
  //       exact: true,
  //       component: WarehouseDetail,
  //       searchable: true
  //     },
  //     {
  //       path: '/inventories/transfer-list',
  //       context: 'Inventories',
  //       title: 'Transfer List',
  //       component: TransferList,
  //       showMenu: false,
  //       searchable: true
  //     },
  //     {
  //       path: '/inventories/transfer/add',
  //       context: 'Inventories',
  //       title: 'Create Transfer',
  //       component: FormTransfer,
  //       showMenu: false,
  //       searchable: true
  //     },
  //     {
  //       path: '/inventories/transfer/:id/:status/(edit)?',
  //       title: 'Edit Transfer',
  //       component: FormTransfer,
  //       showMenu: false,
  //     },
  //   ],
  // },
  {
    path: "/promo",
    title: "Promo",
    name: "promo",
    group: "Marketing",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/promo",
        context: "Promo",
        title: "Promo",
        exact: true,
        component: ViewPromo,
        searchable: true,
      },
      {
        path: "/promo/create",
        context: "Promo",
        title: "Create",
        component: FormPromo,
        searchable: true,
      },
      {
        path: "/promo/edit/:id",
        title: "Edit",
        showMenu: false,
        component: FormPromo,
      },
    ],
  },
  {
    path: "/billings",
    title: "Billing",
    name: "billings",
    group: "Finance",
    component: ContentTabs,
    routes: [
      {
        path: "/billings",
        context: "Billing",
        title: "Dashboard",
        exact: true,
        component: ViewBilling,
        searchable: true,
      },
      // {
      //   path: '/billings/create',
      //   title: 'Create',
      //   component: FormBilling,
      // }
    ],
  },
  {
    path: "/tickets/assembly-old",
    title: "Ticket Assembly Old",
    name: "dashin-legacy",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/assembly-old",
        title: "Ticket Assembly Old",
        context: "Ticket",
        component: TicketAssemblyList,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/assembly-old/:id/(edit)?",
        title: "Ticket Assembly Old",
        context: "Ticket",
        component: TicketDetail,
        showMenu: false,
      },
    ],
  },
  {
    path: "/tickets/assembly",
    title: "Ticket Assembly",
    name: "tickets",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/assembly",
        title: "Ticket Assembly",
        context: "Ticket",
        component: TicketAssemblyListV2,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/assembly/:id/(edit)?",
        title: "Ticket Assembly",
        context: "Ticket",
        component: TicketAssemblyDetailV2,
        showMenu: false,
      },
    ],
  },
  {
    path: "/tickets/installation-old",
    title: "Ticket Installation Old",
    name: "dashin-legacy",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/installation-old",
        title: "Ticket Installation Old",
        context: "Ticket",
        component: TicketInstallationList,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/installation-old/:id/(edit)?",
        title: "Ticket Installation Old",
        context: "Ticket",
        component: TicketDetail,
        showMenu: false,
      },
    ],
  },
  {
    path: "/tickets/installation",
    title: "Ticket Installation",
    name: "tickets",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/installation",
        title: "Ticket Installation",
        context: "Ticket",
        component: TicketInstallationListV2,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/installation/:id/(edit)?",
        title: "Ticket Installation",
        context: "Ticket",
        component: TicketInstallationDetailV2,
        showMenu: false,
      },
    ],
  },
  {
    path: "/tickets/customer",
    title: "Ticket Customer",
    name: "tickets",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/customer",
        title: "Ticket Customer",
        context: "Ticket",
        component: TicketCustomerList,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/customer/:id/(edit)?",
        title: "Ticket Customer",
        context: "Ticket",
        component: TicketDetail,
        showMenu: false,
      },
    ],
  },
  {
    path: "/tickets/return",
    title: "Ticket Return",
    name: "tickets",
    group: "Feeder",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/tickets/return",
        title: "Ticket Return",
        context: "Ticket",
        component: TicketReturnList,
        exact: true,
        searchable: true,
      },
      {
        path: "/tickets/return/view/:id/(edit)?",
        title: "Ticket Return",
        context: "Ticket",
        component: TicketDetail,
        showMenu: false,
      },
      {
        path: "/tickets/return/create",
        title: "Ticket Return",
        context: "Ticket",
        component: CreateTicketReturn,
        showMenu: false,
      },
    ],
  },
  {
    path: "/order-validation",
    title: "Order Validation",
    breadcrumb: null,
    name: "orders",
    group: "Order MGMT",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/order-validation",
        title: "Order Validation",
        breadcrumb: "Order Validation",
        context: "Orders",
        component: OrderList,
        exact: true,
        searchable: true,
      },
      {
        path: "/order-validation/:id",
        title: null,
        breadcrumb: "Detail",
        context: "Orders",
        component: OrderDetail,
        showMenu: false,
      },
    ],
  },
  {
    path: "/visit",
    title: "Visit",
    breadcrumb: null,
    name: "visit-management",
    group: "Visit MGMT",
    component: ContentWithoutTabs,
    routes: [
      {
        path: "/visit",
        title: "Visit Management",
        breadcrumb: "Visit",
        context: "Visit MGMT",
        component: VisitList,
        exact: true,
        searchable: true,
      },
      {
        path: "/visit/:code",
        title: null,
        breadcrumb: "Detail",
        context: "Visit MGMT",
        component: VisitDetail,
        exact: true,
        searchable: true,
      },
    ],
  },
  {
    path: "/reminders",
    title: "Reminders",
    name: "reminders",
    group: "Sales",
    component: ContentTabs,
    searchable: true,
    routes: [
      {
        path: "/reminders",
        context: "Reminders",
        title: "Dashboard",
        exact: true,
        component: DashboardReminders,
      },
      {
        path: "/reminders/create",
        context: "Reminders",
        title: "Create Reminder",
        component: FormReminders,
        searchable: true,
      },
      {
        path: "/reminders/edit/:uuid",
        title: "Edit",
        showMenu: false,
        component: FormReminders,
      },
    ],
  },
  ...sales,
];

const user_access = isAuth2Enable
  ? userStore.getUser().user_permissions || []
  : userStore.getUser().permissions || [];
const is_admin = userStore.getUser().is_admin || false;

const routes_access = default_routes.map((route) => {
  return {
    ...route,
    component:
      is_admin || user_access.some((e) => e.name === route.name)
        ? route.component
        : () => <h2>You do not have permission to access this page</h2>,
  };
});

export default [
  {
    path: "/login",
    title: "Login",
    component: Login,
  },
  {
    path: "/404",
    title: "404",
    component: NoMatch,
  },
  {
    path: "/",
    title: "Dashboard",
    component: withAuth(Dashboard),
    routes: [
      ...routes_access,
      {
        path: "*",
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];
