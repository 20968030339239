import { useEffect, useState } from 'react';

export const useHideTopbar = (location) => {
  const [hideTopbar, setHideTopbar] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const topbar = query.get('topbar');
    setHideTopbar(topbar === '0');
  }, []);

  return hideTopbar;
};
