import React, { createContext, useContext } from "react";
import { message } from "@efishery/onefish-antd";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [messageAPI, contextHolder] = message.useMessage();

  return (
    <MessageContext.Provider value={messageAPI}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);
