import axios from 'axios';
import { captureException, captureMessage } from '@sentry/browser';
import userStore from '../../stores/userStore';
import { sdkauth, isAuth2Enable } from 'libs/api';

export const patrickStarService = axios.create({
  baseURL: process.env.REACT_APP_PATRICK_STAR_SERVICE_URL,
  headers: {
    Authorization: `Bearer ${userStore.getToken()}`,
    'X-Client-Id': 'DASHBOARD-INTERNAL',
  },
});

patrickStarService.interceptors.response.use(
  (response) => response,
  async (err) => {
    if ([403, 401].includes(err.response.status)) {
      if (isAuth2Enable) sdkauth.logout();
      captureMessage('Unauthorized User', 'info');
      userStore.logout();
      window.location.reload(true);
    }

    captureException(err, (scope) => {
      scope.setExtra('Error response', err.response);
    });

    return Promise.reject(err);
  }
);
