import axios from 'axios';
import userStore from '../../stores/userStore';

export const dataBrokerService = axios.create({
  baseURL: process.env.REACT_APP_DATA_BROKER_URL,
});

dataBrokerService.interceptors.response.use(
  (response) => response,
  async (err) => {
    if ([403, 401].includes(err.response.status)) {
      userStore.logout();
      window.location.reload(true);
    }
    return Promise.reject(err);
  }
);
