import React from "react";
import ContentTabs from "components/ContentTabs";
import Webview from "libs/utils/webview";
import { CORE } from "libs/constants/pic";
import withFlagr from "components/withFlagr";
import { flagKey, entityContext } from "libs/constants/area";

const AreaWebview = new Webview({
  baseUrl:
    process.env.REACT_APP_AREA_MODULAR_FE ||
    "https://stg-area-mod-fe-platform-area.efishery.com",
  scope: "area",
});

const ViewAreaPage = React.lazy(() =>
  import("packages/sales/area/legacy/view")
);
const ViewAreaV1Page = AreaWebview.renderWebview({ src: "" });
const CreateAreaPage = React.lazy(() =>
  import("packages/sales/area/legacy/formArea")
);
const CreateAreaV1Page = AreaWebview.renderWebview({ src: "/create" });
const EditAreaPage = React.lazy(() =>
  import("packages/sales/area/legacy/formArea")
);
const EditAreaV1Page = AreaWebview.renderWebview({ src: "/edit" });

export default {
  path: "/area",
  title: "Area",
  name: "area",
  group: "Sales",
  component: ContentTabs,
  pic: {
    ...CORE,
  },
  routes: [
    {
      path: "/area",
      context: "Area",
      title: "View Area",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        ViewAreaV1Page,
        ViewAreaPage
      ),
      searchable: true,
    },
    {
      path: "/area/create",
      context: "Area",
      title: "Create",
      exact: true,
      component: withFlagr(
        flagKey,
        entityContext,
        CreateAreaV1Page,
        CreateAreaPage
      ),
      searchable: true,
    },
    {
      path: "/area/view/:id/(edit)?",
      context: "Area",
      title: "Detail",
      showMenu: false,
      component: withFlagr(
        flagKey,
        entityContext,
        EditAreaV1Page,
        EditAreaPage
      ),
    },
  ],
};
